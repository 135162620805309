<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Remolques</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Remolques</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="pivotTable()"
                        v-if="$store.getters.can('admin.remolques.pivotTable')"
                      >
                        <i class="fas fa-scroll"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_remolque_export"
                        v-if="$store.getters.can('admin.remolques.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="$store.getters.can('admin.remolques.create')"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        Placa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.placa"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo de Carrocería
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipoCarroceria"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="tipoCarroceria in listasForms.tiposCarrocerias"
                            :key="tipoCarroceria.numeracion"
                            :value="tipoCarroceria.numeracion"
                          >
                            {{ tipoCarroceria.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Modelo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.modelo"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Líneas Negocio
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="linea_negocio"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.lineasNegocios"
                          :filterable="true"
                          multiple
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="listasForms.selct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="empresas"
                          :filterable="false"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="remolque in remolques.data" :key="remolque.id">
                      <td>
                        <a
                          :href="uri_docs + remolque.link_fotografia"
                          target="_blank"
                        >
                          <img
                            :src="uri_docs + remolque.link_fotografia"
                            alt="Remolque"
                            width="75"
                            class="rounded mx-auto d-block"
                          />
                        </a>
                      </td>
                      <td>{{ remolque.placa }}</td>
                      <td>{{ remolque.nTipoTipoCarroceria }}</td>
                      <td>{{ remolque.modelo }}</td>
                      <td style="width: 85px" align="right">
                        <div class="row">
                          <div class="col-md-6">
                            <span
                              class="badge"
                              data-toggle="modal"
                              data-target="#modal-form-estado"
                              style="cursor: pointer"
                              v-bind:class="[
                                remolque.estado == 1
                                  ? 'badge-success'
                                  : 'badge-danger',
                              ]"
                              @click="llenarModal(remolque.id, remolque.estado)"
                            >
                              {{ remolque.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                            </span>
                          </div>
                          <div class="col-md-6">
                            <i
                              class="fas fa-eye"
                              data-toggle="modal"
                              data-target="#modal-historial-estado"
                              style="cursor: pointer"
                              v-if="$store.getters.can('admin.remolques.show')"
                              @click="
                                llenarModalHistorial(
                                  'App\\Remolque',
                                  remolque.id
                                )
                              "
                            ></i>
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <div
                          v-for="lineas in remolque.lineas_negocio"
                          :key="lineas.id"
                        >
                          <span class="badge bg-navy">
                            {{ lineas.nombre }}
                          </span>
                        </div>
                      </td>
                      <td>
                        {{
                          remolque.empresa != null
                            ? remolque.empresa.razon_social
                            : "No encontrada"
                        }}
                      </td>

                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <div
                            v-if="
                              $store.getters.can(
                                'admin.remolques.editarTransportadora'
                              ) && !admin
                            "
                          >
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              @click="edit(remolque, 3)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                          <div v-else>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="$store.getters.can('admin.remolques.show')"
                              @click="edit(remolque, 1)"
                            >
                              <i class="fas fa-eye"></i>
                            </button>
                          </div>
                          <div v-if="empresa.length > 0">
                            <div v-if="remolque.tipo_propiedad == 1">
                              <div v-if="remolque.empresa_id">
                                <div>
                                  <button
                                    type="button"
                                    class="btn btn-sm bg-navy"
                                    v-if="
                                      $store.getters.can(
                                        'admin.remolques.edit'
                                      ) && empresa[0].id == remolque.empresa_id
                                    "
                                    @click="edit(remolque, 2)"
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                v-if="
                                  $store.getters.can('admin.remolques.edit')
                                "
                                @click="edit(remolque, 2)"
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                            </div>
                          </div>
                          <div v-else>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="$store.getters.can('admin.remolques.edit')"
                              @click="edit(remolque, 2)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="remolques.total">
                  <p>
                    Mostrando del <b>{{ remolques.from }}</b> al
                    <b>{{ remolques.to }}</b> de un total:
                    <b>{{ remolques.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="remolques"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <RemolqueEstado ref="RemolqueEstado" />
        <HistorialEstado ref="HistorialEstado" />
      </div>
    </div>
    <RemolqueExport ref="RemolqueExport" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import RemolqueEstado from "./RemolqueEstado";
import RemolqueExport from "./RemolqueExport";
import HistorialEstado from "../historialEstado/HistorialEstado";
import vSelect from "vue-select";
export default {
  name: "RemolqueIndex",
  components: {
    pagination,
    RemolqueEstado,
    RemolqueExport,
    HistorialEstado,
    vSelect,
  },
  data() {
    return {
      empresa: this.$store.getters.getUser.empresas,
      linea_negocio: [],
      empresas: [],
      user: null,
      admin: null,
      filtros: {
        lineaNegocio: null,
        placa: null,
        tipoCarroceria: null,
        modelo: null,
        estado: null,
        empresa_id: null,
      },
      remolques: {},
      listasForms: {
        lineasNegocios: [],
        tiposCarrocerias: [],
        estados: [],
        selct_empresa: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {},
  methods: {
    getIndex(page = 1) {
      this.filtros.lineaNegocio = [];
      this.filtros.empresa_id = null;
      this.linea_negocio.forEach((linea) => {
        this.filtros.lineaNegocio.push(linea.id);
      });
      if (this.listasForms.selct_empresa) {
        this.filtros.empresa_id = this.listasForms.selct_empresa.id;
      }
      axios
        .get("/api/admin/remolques?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.remolques = response.data;
        });

        if (this.$store.state.user) {
        const administrador = this.$store.state.user.roles.find(
          (administrador)=> administrador.id === 1,
        );
        if (administrador) {
          this.admin = administrador.id;
        }
      }
    },

    getLineasNegocios() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineasNegocios = response.data;
      });
    },

    getTiposCarrocerias() {
      axios.get("/api/lista/9").then((response) => {
        this.listasForms.tiposCarrocerias = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(idRemolque) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/remolques/" + idRemolque)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el remolque exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    create() {
      return this.$router.push({
        name: "/Admin/RemolqueForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(remolque, opcion) {
      return this.$router.push({
        name: "/Admin/RemolqueForm",
        params: {
          accion: "Editar",
          data_edit: remolque,
          id: remolque.id,
          opcion: opcion,
        },
      });
    },
    pivotTable() {
      return this.$router.push({
        name: "/Admin/RemolquePivotTable",
      });
    },

    llenarModal(id, estado) {
      if (this.$store.getters.can("admin.remolques.editStatus")) {
        this.$refs.RemolqueEstado.llenar_modal(id, estado);
      }
    },

    llenarModalHistorial(entidad, entidad_id) {
      if (this.$store.getters.can("admin.remolques.show")) {
        this.$refs.HistorialEstado.llenar_modal(entidad, entidad_id);
      }
    },

    generarListadoExcel() {
      this.$parent.cargando = true;

      axios({
        method: "POST",
        url: "/api/admin/remolques/export",
        data: this.form,
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },
  mounted() {
    this.getIndex();
    this.getLineasNegocios();
    this.getTiposCarrocerias();
    this.getEstados();
  },
};
</script>
